import { baseURL, ports } from "app/utils/constant";
import axios from "axios";
import { spLoginAPI, serviceProviderDetails, employeeLogin, employeeInfo } from './APIContainer';
import { encryptData } from "app/utils/utils";

function getBaseUrl(url, type) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  return url;
  /* if (url === "login") {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("access-management")) {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("home-management")) {    
    axios.defaults.baseURL = baseURL+ports.home;    
  }
  else if (url.includes("report-management")) {    
    axios.defaults.baseURL = baseURL+ports.report;    
  }
  else {
    axios.defaults.baseURL = baseURL+ports.payroll;    
  }
  return url; */
}

export function getAPIUrl(url) {
  let baseURLVal = baseURL+ports.payroll;
  baseURLVal = baseURLVal+url
  return baseURLVal;
}

async function setAuthorizationToken(url) {  
  const accessToken = window.localStorage.getItem("accessToken")
  if (url == employeeLogin) {
    const token = `Bearer ${accessToken}`    
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`    
    axios.defaults.headers["Authorization"] = token;
  } else {
    delete axios.defaults.headers["Authorization"];
  }
}

export function returnApiCallPromise(method, url, object = null, config = null) {    
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  axios.defaults.headers['unitid'] = 1;
  axios.defaults.headers['type'] = "web";
  axios.defaults.headers['zoneid'] = "1";//to get service list(without zone id service list will become empty in future we need to remove this)
  axios.defaults.headers['user_id'] = 1;
  axios.defaults.headers['product'] = "kp";
  
  // delete axios.defaults.headers["Authorization"];
  // setAuthorizationToken(url)
  //axios.create({withCredentials : true});
  // axios.defaults.headers['org_id'] = global.OrgID ? global.OrgID : 0;
  // axios.defaults.headers['branchid'] = global.branchId ? global.branchId : 0;
  // axios.defaults.headers['usercode'] = global.usercode;  
  // axios.defaults.headers['counterid'] = global.counterId ? global.counterId : 0;
  // eslint-disable-next-line default-case
  /* switch (method) {
    case global.GET:
      const requestData = object ? { token : encryptData(object?.params) } : null
      const request = requestData ? { params : requestData } : null      
      return axios.get(getBaseUrl(url), request);
    case global.POST: {
      const postRequest = object ? { token : encryptData(object) } : null 
      return axios.post(getBaseUrl(url), postRequest, config);
    }
    case global.PUT:
      const putRequest = object ? { token : encryptData(object) } : null
      return axios.put(getBaseUrl(url), putRequest, config);
    case global.DELETE:
      return axios.delete(getBaseUrl(url), object, config);
    case global.GETParams:
      return axios.put(url, object, config);
  } */
  
  switch (method) {
    case 'GET':
      const requestData = object?.params ? { token : encryptData(object?.params) } : null
      const request = requestData ? { params : requestData } : null      
      return axios.get(getBaseUrl(url), request, config);
    case 'POST':   
    const postRequest = object ? { token : encryptData(object) } : null    
      return axios.post(getBaseUrl(url), postRequest, config);
    case 'PUT':
      const putRequest = object ? { token : encryptData(object) } : null
      return axios.put(getBaseUrl(url), putRequest, config);
    case 'DELETE':
      const deleteRequest = object ? { token : encryptData(object) } : null
      return axios.delete(getBaseUrl(url), deleteRequest, config);
    case 'FORMPUT':
      const configs = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      const fomrPutRequest = object ? { token : encryptData(object) } : null
      return axios.put(getBaseUrl(url), fomrPutRequest, configs);
    case 'FORMPOST':
      const postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      const formPostRequest = object ? { token : encryptData(object) } : null    
      return axios.post(getBaseUrl(url), formPostRequest, postConfig);
  }
}
