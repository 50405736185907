import { lazy } from "react";

const ServiceProviderList = lazy(() => import("./serviceProviderList"));
const ServiceProviderEnquery = lazy(() => import("./serviceProviderEnquery"));

const serviceProviderRoutes = [
  {
    path: "/service-provider",
    element: <ServiceProviderList />,
  },
  {
    path: "/service-provider-enquery",
    element: <ServiceProviderEnquery />,
  }
];

export default serviceProviderRoutes;
