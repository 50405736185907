
const initialState = {
    serviceEnqueryList: []
}

const ServiceEnqueryReducer = function (state = initialState, action) {
    switch (action.type) {        
        case "GET_SERVICE_ENQUERY_SUCCESS": {
            return {
                ...state,
                getServiceEnquerySuccess: true,
                getServiceEnqueryList: action.payload.data,
            };
        }
        case "GET_SERVICE_ENQUERY_FAILURE": {
            return {
                ...state,
                getServiceEnqueryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_SERVICE_ENQUERY": {
            return {
                ...state,
                getServiceEnquerySuccess: false,
                getServiceEnqueryFailure: false,
                getServiceEnqueryList: [],
                errorMessage: false
            };
        }

        case "CREATE_SERVICE_ENQUERY_SUCCESS": {
            return {
                ...state,
                createServiceEnquerySuccess: true,
                createServiceEnqueryData: action.payload.data,
            };
        }
        case "CREATE_SERVICE_ENQUERY_FAILURE": {
            return {
                ...state,
                createServiceEnqueryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_SERVICE_ENQUERY": {
            return {
                ...state,
                createServiceEnquerySuccess: false,
                createServiceEnqueryFailure: false,
                createServiceEnqueryData: false,
                errorMessage: false
            };
        }

        case "UPDATE_SERVICE_ENQUERY_SUCCESS": {
            return {
                ...state,
                updateServiceEnquerySuccess: true,
                updateServiceEnqueryData: action.payload.data,
            };
        }
        case "UPDATE_SERVICE_ENQUERY_FAILURE": {
            return {
                ...state,
                updateServiceEnqueryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_SERVICE_ENQUERY": {
            return {
                ...state,
                updateServiceEnquerySuccess: false,
                updateServiceEnqueryFailure: false,
                updateServiceEnqueryData: false,
                errorMessage: false
            };
        }
        
        case "CREATE_SERVICE_ENQUERY_LOG_SUCCESS": {
            return {
                ...state,
                createServiceEnqueryLogSuccess: true,
                createServiceEnqueryLogData: action.payload.data,
            };
        }
        case "CREATE_SERVICE_ENQUERY_LOG_FAILURE": {
            return {
                ...state,
                createServiceEnqueryLogFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_SERVICE_ENQUERY_LOG": {
            return {
                ...state,
                createServiceEnqueryLogSuccess: false,
                createServiceEnqueryLogFailure: false,
                createServiceEnqueryLogData: false,
                errorMessage: false
            };
        }

        default: {
            return {
                ...state,
            }
        }
    }
}

export default ServiceEnqueryReducer

