const initialState = {
    serviceProviderList: []  
  }
  
  const ServiceProviderReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PARTNER_ENQUERY_SUCCESS": {             
        return {
          ...state,
          getPartnerEnquerySuccess: true,
          getPartnerEnqueryList: action.payload,
        };
      }
      case "GET_PARTNER_ENQUERY_FAILURE": {
        return {
          ...state,
          getPartnerEnqueryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PARTNER_ENQUERY": {
        return {
          ...state,
          getPartnerEnquerySuccess: false,
          getPartnerEnqueryFailure: false,
          getPartnerEnqueryList: [],
          errorMessage: false
        };
      }  


      case "GET_SERVICE_PROVIDER_SUCCESS": {             
        return {
          ...state,
          getServiceProviderSuccess: true,
          getServiceProviderList: action.payload.data,
        };
      }
      case "GET_SERVICE_PROVIDER_FAILURE": {
        return {
          ...state,
          getServiceProviderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_PROVIDER": {
        return {
          ...state,
          getServiceProviderSuccess: false,
          getServiceProviderFailure: false,
          getServiceProviderList: [],
          errorMessage: false
        };
      }      
      case "CREATE_SERVICE_PROVIDER_SUCCESS": {             
        return {
          ...state,
          createServiceProviderSuccess: true,
          createServiceProviderData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_PROVIDER_FAILURE": {
        return {
          ...state,
          createServiceProviderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_PROVIDER": {
        return {
          ...state,
          createServiceProviderSuccess: false,
          createServiceProviderFailure: false,
          createServiceProviderData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_PROVIDER_SUCCESS": {             
        return {
          ...state,
          updateServiceProviderSuccess: true,
          updateServiceProviderData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_PROVIDER_FAILURE": {
        return {
          ...state,
          updateServiceProviderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_PROVIDER": {
        return {
          ...state,
          updateServiceProviderSuccess: false,
          updateServiceProviderFailure: false,
          updateServiceProviderData: false,
          errorMessage: false         
        };
      }
      //
      case "DELETE_SERVICE_PROVIDER_SUCCESS": {             
        return {
          ...state,
          deleteServiceProviderSuccess: true,
          deleteServiceProviderData: action.payload.data,
        };
      }
      case "DELETE_SERVICE_PROVIDER_FAILURE": {
        return {
          ...state,
          deleteServiceProviderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_SERVICE_PROVIDER": {
        return {
          ...state,
          deleteServiceProviderSuccess: false,
          deleteServiceProviderFailure: false,
          deleteServiceProviderData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceProviderReducer
  
  