import { lazy } from "react";

const NewServiceOrderList = lazy(() => import("./newServiceOrder"));
const BacklogList = lazy(() => import("./backlog"));
const ServiceOrderList = lazy(() => import("./serviceOrderList"));
const SosServiceList = lazy(() => import("./sosServiceList"));
const ReviewList = lazy(() => import("./reviewList"));
const ServiceEnquery = lazy(() => import("./serviceEnquery"));

const ServiceRoutes = [
  {
    path: "/newservice-order",
    element: <NewServiceOrderList />,
  },
  {
    path: "/backlog",
    element: <BacklogList />,
  },
  {
    path: "/service-order",
    element: <ServiceOrderList />,
  },
  {
    path: "/sos-service",
    element: <SosServiceList />,
  },
  {
    path: "/service-order-review",
    element: <ReviewList />,
  },
  {
    path: "/service-enquery",
    element: <ServiceEnquery />,
  },
];

export default ServiceRoutes;
